import Link from "next/link";
import React, { useState } from "react";

import { useSubscribeToNewsletterMutation } from "app/newsletter/newsletter.mutation.generated";
import { Button } from "ui/Button";
import { isEmail } from "utils/validators.utils";
import { useI18n } from "utils/with-i18n.utils";

interface MailEntryProps {
  onClick: () => void;
}
export const MailEntry = ({ onClick }: MailEntryProps) => {
  const i18n = useI18n();
  const [email, setEmail] = useState("");
  const [showError, setShowError] = useState(false);
  const [subscribeToNewsletter, { loading }] =
    useSubscribeToNewsletterMutation();

  const handleOnClick = async () => {
    if (isEmail(email)) {
      setShowError(false);
      await subscribeToNewsletter({
        variables: { mail: email},
      });
      onClick();
    } else {
      setShowError(true);
    }
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    setShowError(false);
  };

  return (
    <>
      <p
        className="text-base text-center md:text-3xl"
        style={{ fontWeight: 700 }}
      >
        {i18n.t("newsletter.mailEntry.subscribe")}
      </p>
      <p
        className="text-base font-bold md:text-3xl text-yellow"
        style={{
          WebkitTextStroke: "1px black",
          paintOrder: "stroke fill",
          filter: "drop-shadow(2px 1px #000000)",
        }}
      >
        {i18n.t("newsletter.mailEntry.toReceive")}
      </p>
      <div className="w-4/6 text-center">
        <p className="pt-5 text-sm md:text-lg">
          {i18n.t("newsletter.mailEntry.discoverGoodDeal")}
        </p>
      </div>

      <div className="flex flex-col justify-center items-center pt-6 w-full text-sm md:pt-10 md:text-base">
        <input
          id={"mailAddress"}
          name={"mailAddress"}
          type={"email"}
          className={`pr-10 pl-5 w-4/5 h-12 placeholder:font-normal placeholder:text-black-alpha-035 rounded-lg border ${
            showError ? "border-french-rose" : "border-black-alpha-035"
          }`}
          placeholder={i18n.t(
            "newsletter.mailEntry.yourMailAddressPlaceholder",
          )}
          value={email}
          onChange={handleOnChange}
        />
        {showError && (
          <p className="absolute mt-20 text-french-rose">
            {i18n.t("newsletter.mailEntry.mailAddressErrorHint")}
          </p>
        )}
      </div>

      <div className="text-center">
        <Button
          className="mt-8 md:px-16 md:mt-12"
          onClick={handleOnClick}
          disabled={loading}
        >
          {i18n.t("newsletter.mailEntry.receiveSurpriseButton")}
        </Button>

        <p className="p-6 text-xs text-center md:pt-14 text-black-alpha-05">
          {i18n.t("newsletter.mailEntry.youAccept")}{" "}
          <Link href="/confidentialite" passHref={true}>
            <a className="underline">
              {i18n.t("newsletter.mailEntry.privacyPolicy")}
            </a>
          </Link>
        </p>
      </div>
    </>
  );
};
