import { Maybe } from "graphql";

import { useGetNavbarCustomerOrderQuery } from "shared/Navbar/getNavbarCustomerOrder.generated";
import LogoutSVG from "shared/Navbar/logout.svg";
import { Shadow } from "ui/Button";
import { useLogout } from "utils/useLogout";

import { ContactUs } from "./ContactUs";
import { LoginButtonBusiness } from "./LoginButtonBusiness";

type LoginBlockProps = {
  customerId: Maybe<number>;
};

export const LoginBlockBusiness = ({ customerId }: LoginBlockProps) => {
  const logout = useLogout();

  const hasSession = !!customerId;

  const { data: { customer } = {} } = useGetNavbarCustomerOrderQuery({
    variables: { customerId: customerId! },
    skip: !customerId,
  });

  return (
    <div className={"flex relative items-center lg:pl-[44px]"}>
      <LoginButtonBusiness
        hasSession={hasSession}
        customerName={customer?.firstName || ""}
        className={
          "px-0 leading-5 lg:py-[9.6px] lg:px-6 lg:mb-[0px] lg:ml-[12px]"
        }
      />

      <ContactUs
        className="py-[12px] px-[24px] lg:py-[11px] lg:ml-[12px]"
        shadow={Shadow.None}
      />

      {hasSession && (
        <button onClick={logout} className="hidden lg:block">
          <LogoutSVG />
        </button>
      )}
    </div>
  );
};
